@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Unilab';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2205-2@1.0/Unilab.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html, body, .Toastify__toast-body {
  font-family: 'Unilab' !important;
}

main {
  padding: 10px;
}

.custom-image span {
  position: static !important;
}
.custom-image span img {
  position: static !important;
  height: 100% !important;
}